import * as React from 'react';

export const HamburgerIcon = (props: any) => (
	<svg viewBox='0 0 16 16' fill='none' {...props}>
		<line
			x1='1.6'
			y1='3.4'
			x2='14.4'
			y2='3.4'
			stroke='#666666'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='1.6'
			y1='7.4'
			x2='14.4'
			y2='7.4'
			stroke='#666666'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<line
			x1='1.6'
			y1='11.4'
			x2='14.4'
			y2='11.4'
			stroke='#666666'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
