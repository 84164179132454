import * as React from 'react';

export const AppIconSvg = (props: any) => {
	return (
		<svg
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M11.3333 1.33331H4.66665C3.93027 1.33331 3.33331 1.93027 3.33331 2.66665V13.3333C3.33331 14.0697 3.93027 14.6666 4.66665 14.6666H11.3333C12.0697 14.6666 12.6666 14.0697 12.6666 13.3333V2.66665C12.6666 1.93027 12.0697 1.33331 11.3333 1.33331Z'
				stroke='#666666'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8 12H8.00667'
				stroke='#666666'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
