import React from 'react';

type Props = {
	fetchingStatus?: number;
};

type State = any;

class Loader extends React.Component<Props, State> {
	constructor(props: any, context: any) {
		super(props, context);
		this.state = { className: '' };
	}

	componentDidMount() {
		this.updateAndSetState(this.props);
	}

	UNSAFE_componentWillReceiveProps(newProps: any) {
		// @ts-expect-error TS(2532): Object is possibly 'undefined'.
		if (this.props.fetchingStatus > 0 !== newProps.fetchingStatus > 0) {
			this.updateAndSetState(newProps);
		}
	}

	updateAndSetState(props: any) {
		const state = this.state;
		const timeForTransitions = 500;
		if (props.fetchingStatus === 0) {
			if (state.className === '') {
				// nothing to do here.
			} else if (state.className === 'start') {
				// it was in running stage, its going to finish
				// @ts-expect-error TS(2542): Index signature in type 'Readonly<any>' only permi... Remove this comment to see the full error message
				state.className = 'finish';
				// we need to trigger it back to '' so that next increment shows it perfectly
				setTimeout(() => {
					this.updateAndSetState(this.props);
				}, timeForTransitions);
			} else if (state.className === 'finish') {
				// @ts-expect-error TS(2542): Index signature in type 'Readonly<any>' only permi... Remove this comment to see the full error message
				state.className = '';
			}
		} else if (props.fetchingStatus >= 1) {
			// api call under progress
			if (state.className === '') {
				// @ts-expect-error TS(2542): Index signature in type 'Readonly<any>' only permi... Remove this comment to see the full error message
				state.className = 'start';
			} else if (state.className === 'start') {
				// nothing to do here
			} else if (state.className === 'finish') {
				// first we need to set it to '' then do the update again in some ms.
				// @ts-expect-error TS(2542): Index signature in type 'Readonly<any>' only permi... Remove this comment to see the full error message
				state.className = '';
				setTimeout(() => {
					this.updateAndSetState(this.props);
				}, timeForTransitions);
			}
		}

		this.setState(state);
	}

	render() {
		return <div className={`loader ${this.state.className}`} />;
	}
}

export default Loader;
