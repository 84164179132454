import { getCityFromLocationObject } from 'Utils/categoryUtils';
import { getProductIdFromLocation } from 'Utils/productUtils';
import {
	getCategoriesInfoById,
	getCity,
	getCityCodeFromCityId,
	getCollectionById,
	getProduct,
	getSubCategoriesInfoById,
} from 'Utils/stateUtils';

import { NEXT_LOCATION_PAGE_PATH } from 'Constants/constants';

export const getAppMetaTags = () => {
	let tags = [];
	tags.push({
		name: 'twitter:card',
		content: 'app',
	});
	tags.push({
		name: 'twitter:site',
		content: '@headout',
	});
	tags.push({
		name: 'twitter:description',
		content:
			'Headout Inc. is an on-demand mobile concierge service that helps travelers and natives to discover and book tours, local experiences and various other activities on the go.', // eslint-disable-line max-len,
	});
	tags.push({
		name: 'twitter:app:id:iphone',
		content: '899327000',
	});
	tags.push({
		name: 'twitter:app:id:googleplay',
		content: 'com.tourlandish.chronos',
	});
	tags.push({
		name: 'twitter:app:id:ipad',
		content: '899327000',
	});
	return tags;
};

export const getPageDataFromLocation = (state: any, location: any) => {
	switch (location.pathname) {
		case NEXT_LOCATION_PAGE_PATH.CATEGORY_PAGE: {
			const { id: categoryId, cityId } = location?.query ?? {};
			const cityCode = getCityCodeFromCityId({ state, cityId });
			return getCategoriesInfoById(state, categoryId, cityCode);
		}
		case NEXT_LOCATION_PAGE_PATH.CITY_PAGE: {
			return getCity(state, getCityFromLocationObject(location));
		}
		case NEXT_LOCATION_PAGE_PATH.EXPERIENCE_PAGE: {
			return getProduct(state, getProductIdFromLocation(location));
		}
		case NEXT_LOCATION_PAGE_PATH.COLLECTION_PAGE: {
			const { id: collectionId } = location?.query ?? {};
			return getCollectionById(state, collectionId);
		}
		case NEXT_LOCATION_PAGE_PATH.SUB_CATEGORY_PAGE: {
			const { id: subCategoryId, cityId } = location?.query ?? {};
			const cityCode = getCityCodeFromCityId({ state, cityId });
			return getSubCategoriesInfoById(state, subCategoryId, cityCode);
		}
	}
	return null;
};
